import React from "react"
import HubspotForm from "components/hubspot"

// Libraries
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"

const StyledContactForm = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;

	  ${breakpoint.medium`
    	width: 50%;
	  `}


  ${Container} {
   max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 0;
    padding: 0;

	  ${breakpoint.medium`
		column-gap: 10%;
	  `}

  }
  
  .top__section {
  		width: 100%;
  		min-width: 200px;
    	padding: 20px 20px 0 20px;
  		
	  ${breakpoint.medium`
		padding: 0 0 0 20px;
		min-width: 580px;
	  `}

	h1 {
		color: ${colors.white};
		font-size: 36px;
		padding: 24px 0px 36px;
		width: 100%;
  
		${breakpoint.small`
			text-align: left;
		`}
	}


  	h4 {
		color: ${colors.blue};
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
  
		${breakpoint.small`
			text-align: left;
		`}
  	}

  }
  
   .left__column {
		flex: 1 0 30%;
		max-width: 550px;
		min-width: 380px;
		padding: 4px 20px 0;
		width: 100%;	
		margin: 0 auto;	
		
	  ${breakpoint.medium`
		margin: 0;
	  `}

  }
  

	.landing__formMessage {
	  display: ${props => (props.viewAllowed ? "block" : "none")};
		background: ${colors.white};
		max-width: 500px;
		flex: 1 0 auto;
		width: 100%;
		padding: 10px;
		margin: 0px auto 25px;
		
	    ${breakpoint.medium`
 			max-width: 550px;
    	`}

		.msg-note {
			font-size: 18px;
			line-height: 1.4em;
			margin-block: 20px;
			text-align: center;
		}

}
	.landing__form {
		display: ${props => (props.viewAllowed ? "none" : "block")};
		background: ${colors.white};
		max-width: 500px;
		flex: 1 0 auto;
		width: 100%;
		padding: 20px;
		margin: 0px auto 25px;
		
	    ${breakpoint.medium`
 			max-width: 550px;
    	`}

	
		h3 {
			padding: 24px 0px 16px 16px;
		}
    
    	h4 { 
    		padding: 0 10px 10px; 

			${breakpoint.medium`
				padding: 10px 20px 15px; 
			`}
    	}
     
		
		
	}

			a {
			  color: ${colors.orange};

			&:hover {
			  color: ${colors.purpleDark};
			  }
			}

`

const ContactForm = props => {

  return (

    <React.Fragment>
      <StyledContactForm viewAllowed={props.viewAllowed} >
        <div className="top__section">
          <h4>Webinar Replay</h4>
          <h1>
            {props.videoDisplayTitle}
          </h1>
        </div>

        <div className="left__column">

          <div className="landing__formMessage">
            <div className="form-element">
              <p className="msg-note">You already have access to this <Link to={props.pageSlug}>webinar replay</Link>.</p>
            </div>
          </div>
          <div className="landing__form">
            <h4>Please complete the following to have access to the full webinar:</h4>
            <HubspotForm
								formId='bc0e91d8-3e2c-4a84-b127-5d537bb00138'
							/>
          </div>
        </div>
      </StyledContactForm>
    </React.Fragment>
  )
}

class FullForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewAllowed: false,
      enableBothFields: false
    }
  }


  render = () => (
    <ContactForm
      pageName={this.props.pageName}
      viewAllowed={this.state.viewAllowed}
      pageSlug={this.props.pageSlug}
      localStorageID={this.props.localStorageID}
      videoDisplayTitle={this.props.videoDisplayTitle}
    />
  )
}

export default FullForm