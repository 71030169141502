import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

const StyledPresenter = styled.div`

   .right__column {    	
		margin-top: 5%;
		max-width: 450px;
		min-width: 280px;
		
		${breakpoint.medium`
			margin-top: 20%;
		`}
	
	h3 {
		font-size: 24px;
		color: ${colors.white};
		text-align: center;
		padding: 0px 0 10px 10px;

		${breakpoint.medium`
			text-align: left;
		`}
	}

	p {
		color: ${colors.white};
		font-size: 16px;
		line-height: 1.4rem;
		margin-top: 15px;
		text-align: center;
  
		${breakpoint.medium`
			padding-left: 100px;
			text-align: left;
		`}
	}
	
	.title {
		font-size: 19px;
		font-weight: 600;
	}
  	
    a {
		color: ${colors.orange};

		&:hover {
			color: ${colors.purpleDark};
		}
    }
    ${breakpoint.medium`
        width: 40%;
    `}
    
  	.columnPresenter {
		display: grid;

		${breakpoint.medium`
			display: flow-root;
		`}
	}

  }


  .right__columnNew {    	
	margin-top: 5%;
}


  .videoReplay {
	margin: 5px 0 60px;
  }
  
	.presenter__image {
		width: 200px;
		height: auto;
		max-height: 200px;
		margin: 0 auto;

		${breakpoint.medium`
			float: left;
			font-size: 13px;
			margin: 0 20px 0 0;
		`}

		img {
			border-radius: 50%;
			object-fit:cover;
			object-position: top center!important;
		}
	}

`


export default StyledPresenter
