
// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container"
// BG
import HeroBg from "assets/images/homepage/threesections/sexual-health-test.jpg"

const StyledContactContainer = styled.div`
	background: url(${HeroBg});
	background-size: cover;
	background-position-x: 20% !important;
	background-position-y: center!important;
	background-repeat: no-repeat;
  	padding: 64px 20px; 
	background-color: rgba(137, 85, 198, 0.9);
	background-blend-mode: multiply;
	opacity: 0.90;

  ${Container} {
	max-width: 1100px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	column-gap: 0;
	padding: 0;

		${breakpoint.medium`
		column-gap: 10%;
		`}
  }
  `
export default StyledContactContainer
