import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"

// Components
import Container from "components/container"
import WebinarForm from "components/webinar/form-hubspot"
import WebinarPresenter from "components/webinar/presenter"
import WebinarContainer from "components/webinar/form-container"
import FeaturedPage from "components/webinar/featured-page"

const FormFull = props => {

	return (
		<WebinarContainer
			viewAllowed={props.viewAllowed}
		>
			<WebinarPresenter>
				<Container data-aos="fade-up">
					{props.featured === true ?
						<FeaturedPage
							videoDisplayTitle={props.title}
							featured={props.featured}
							featuredDate={props.featuredDate}
							featuredTime={props.featuredTime}
							featuredCost={props.featuredCost}
							featuredUrl={props.featuredUrl}
							presenter={props.presenter}
							presenterSubtitle={props.presenterSubtitle}
							extraMessage={props.extraMessage}
						/>
						:
						<React.Fragment>
							<WebinarForm
								formId='bc0e91d8-3e2c-4a84-b127-5d537bb00138'
								localStorageID="2023-10-27-fish-webinar-replay"
							/>
						</React.Fragment>
					}
					{props.featured === true ? null :
						<div className="right__column right__columnNew">
							<h3>Video</h3>
							<StaticImage
								src="../../../../assets/images/webinar-replay/2023-10-27-fish-webinar-replay.jpg"
								width={1260}
								alt="Video replay image"
								className="videoReplay"
							/>

							<h3>Presenter</h3>
							<div className="columnPresenter">
								<StaticImage
									src="../../../../assets/images/webinar-replay/fish.jpg"
									width={200}
									alt="Dr. Lindsey E. Fish"
									className="presenter__image"
								/>
								<p><span className="title">Lindsey Fish, MD</span><br />Medical Director at Peña Urgent Care Clinic, Denver Health and Hospital System</p>
							</div>
						</div>
					}
				</Container>
			</WebinarPresenter>
		</WebinarContainer>
	)
}

export default FormFull
