import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container"
import { ExternalButtonLink } from "components/buttons"


const StyledFeaturedPage = styled.div`
    display: flex;
    flex-direction: column;
    width: auto;
    color: ${colors.white};


  ${Container} {
   max-width: 1100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 0;
    padding: 0;

	  ${breakpoint.medium`
		column-gap: 10%;
	  `}

  }
  
  .top__section {
  		width: 100%;
  		min-width: 200px;
    	padding: 20px 20px 0 20px;
      text-align: center;
  		
      ${breakpoint.small`
			text-align: left;
		`}

	  ${breakpoint.medium`
		padding: 0 0 0 20px;
		min-width: 580px;
	  `}

	h1 {
		color: ${colors.white};
		font-size: 36px;
		padding: 24px 0 36px;
		width: 100%;
  
		${breakpoint.small`
			text-align: left;
      width: 95%;
		`}
	}


  	h4 {
		color: ${colors.blue};
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
  
		${breakpoint.small`
			text-align: left;
		`}
  	}

    .extra {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.4em; 
    }

    .eventDate {
      margin-top: 1em;
      font-size: 20px;
      line-height: 1.4em;      
    }

  }
  
   .left__column {
		flex: 1 0 30%;
		max-width: 550px;
		min-width: 380px;
		padding: 4px 20px 0;
		width: 100%;	
		margin: 0 auto;	
		
	  ${breakpoint.medium`
		margin: 0;
	  `}

  }
  
}

.externalButton {
  margin: 20px 0 0;
  width: 100%; 

  ${breakpoint.small`
  width: auto; 
  `}
}

			a {
			  color: ${colors.charcoal};

			&:hover {
			  color: ${colors.white};
			  }
			}

`

const FeaturedPage = props => {

  return (
    <StyledFeaturedPage>
      <div className="top__section">
        <h4>Upcoming Webinar</h4>
        <h1>
          {props.videoDisplayTitle}
        </h1>
        <h3>{props.presenter}</h3>
        <p>{props.presenterSubtitle}</p>

        <p className="eventDate">{props.featuredDate}<br />
        {props.featuredTime}<br />
        Cost: {props.featuredCost}</p>
        <p className="extra">{props.extraMessage}</p>
        <ExternalButtonLink
          href={props.featuredUrl}
          target="_blank" rel="noopener noreferrer"
          backgroundcolor="orange"
          className="externalButton"
        >Register
        </ExternalButtonLink>
      </div>

    </StyledFeaturedPage>
  )
}

export default FeaturedPage